import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Layout from "../components/layout/ca"
import SEO from "../components/seo"
import HeroImage from "../components/ui-sections/hero-image"
import ImageGallery from "../components/ui-sections/image-gallery"
import TextSection from "../components/ui-sections/text-section"

const CuinaPage = (props) => {
	const heroText = "Cuina"
	const textSectionText = "Producte de temporada i de proximitat. Combinem la cuina tradicional dels avis, amb els matissos d'avantguarda gastronòmica que tenim avui dia. Estimem la nostra terra i sentim com a propis els pèsols de Llavaneres, la vedella de Girona, les gambes de Vilanova o les carxofes del Prat. El Gall del Penedès, el préssec de Sant Pau d'Ordal i el tomàquet de Montserrat. Ens sabem privilegiats de tenir a l'abast aquests tresors i la nostra cuina és l'expresió més sincera de la nostra passió. Esperem que us agradi."
	return (
	  <Layout location={props.location}>
	    <SEO 
	    	title="Cuina"
	    	lang="ca"
	    	description={textSectionText}
	    />
	    <HeroImage 
	    	titleText={heroText}
	    	image="cuina" 
	    />
	    <TextSection textSectionText={textSectionText} />
	    <StaticQuery
	      query={graphql`
	        # Replace this comment with the GraphQL query above
	        {
	          allCuinaImagesCaYaml {
	            edges {
	              node {
	                title
	                image {
	                	id
	                  childImageSharp {
	                    fluid {
	                      aspectRatio
	                      originalImg
	                      # Use gatsby-image later for better user experience
	                      ...GatsbyImageSharpFluid
	        }}}}}}}
	      `}
	      render={data => (
	        <ImageGallery
	          itemsPerRow={[1, 3]} // This will be changed to `[2, 3]` later
	          images={data.allCuinaImagesCaYaml.edges.map(({ node }) => ({
	          	id: node.image.id,
	            ...node.image.childImageSharp.fluid,
	            caption: `${node.title}`,
	          }))}
	        />
	      )}
	    />
	  </Layout>
	)
}

export default CuinaPage
